<template>
  <div id="app">
    <div v-if="is_full_data">
    <div class="title">
      {{ title }}
    </div>
    <div class="desc text-gray p2 lh2 m2">
      {{ desc }}
    </div>
      <!-- <van-form @submit="onSubmit"> -->
  <van-field
    v-for="(item) in group"
    :key="item.id"
    v-model="fields[item.field]"
    :name="item.name"
    :label="item.name"
    :placeholder="'请输入'+item.name"
    :type="item.type=='textarea'?'textarea':'text'"
  />

  <div style="margin: 16px;">
    <van-button :disabled="loading || is_add" type="primary" round block @click="submit">提交</van-button>
  </div>
  <div class="p1 t-s1 text-red" v-if="is_add">
    您于 {{ add_time_str }} 提交信息
  </div>
<!-- </van-form> -->
    </div>
    <div v-else>
      <div ckey="m2">参数错误</div>
    </div>
  </div>
</template>

<script>
import { Toast,Dialog } from 'vant2'
import {getForm,insertAnswer} from '@/api/form'
export default {
  name: 'App',
  data(){
    return {
      loading:false,
      id:this.$route.query.id*1,
      title: '',
      desc:'',
      group:[],
      fields:{},
      query:{
        question_id:0,
        uid:0,
        user_name:'',
        mobile:'',
        answer:{}
      },
      is_full_data:false,
      is_add:false,
      add_time_str:''
    }
  },
  created(){
    this.query.question_id=this.$route.query.id*1
    this.query.uid=this.$route.query.uid*1||0
    this.query.user_name=this.$route.query.user_name
    this.query.mobile=this.$route.query.mobile

    console.log('created',this.$route.query.id*1)
  },
  mounted(){
    if(!this.query.uid || !this.query.user_name || !this.query.mobile){
      return
    }
    this.is_full_data=true
    this.getForm()
  },
  methods:{
   submit() {
      const l=Object.keys(this.fields).length
      if(l!=this.group.length || !l) return Toast('请认真填写字段内容，字段内容不允许为空')
      this.query.answer=[]
      for(let x in this.fields){
          // console.log(x,this.fields[x]);
          this.query.answer.push({field:x,value:this.fields[x]})
      }
      console.log(this.query)
      Dialog.confirm({
        title: '提交？',
        message: '确定要提交数据吗？',
        })
        .then(async () => {
          this.loading=true
          const {data,msg}=await insertAnswer(this.query)
            if(data){
              Toast.success('提交数据成功')
              this.is_add=true
              this.add_time_str=data.add_time_str||''
            }else{
              Toast.fail(msg||'提交数据失败')
            }
            this.loading=false
        })
        .catch(() => {
          // on cancel
      });
      
    },
    
    async getForm(){
      Toast.loading()
      const {data,msg,answer}=await getForm({id:this.id,uid:this.query.uid}).catch(e=>{Toast.fail(e.message)})
      Toast.clear()
      // console.log(data,msg)
      if(data){
        let fileds={}
        this.title=data.title
        this.desc=data.desc
        this.group=data.group
        data.group.forEach(item=>{
          this.$set(fileds,item.field,'')
        })
        if(answer && answer.answer){
          this.add_time_str=answer.add_time_str
          this.is_add=true
          answer.answer.forEach(item=>{
            this.fields[item.field]=item.value
          })
      }
        
      }else{
        Toast(msg||'获取数据失败')
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
.title{
  font-size: 16px;
  font-weight: bold;
}
.p1{
  padding:10px;
}
.m2{
  margin: 20px;
}
.p2{
  padding: 20px;
}
.lh2{
  line-height: 200%;
}
.desc{
  text-align: left;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.text-gray{
  color:#666;
}
.t-s1{
  font-size: 14px;
}
.text-red{
  color:#f00;
}
</style>
